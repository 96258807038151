//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import LIST_MIXIN from '@mixins/list-page'
import COMMON_METHODS from '@mixins/common-methods.js'
import addPayee from '@/views/settmg/views/ShengNeiSetItem/components/addPayee';
import applyShengneiSett from '@/views/settmg/views/ShengNeiSetItem/applyShengneiSett'
import audit from '@/views/settmg/views/ShengNeiSetItem/audit'
import sett from '@/views/settmg/views/ShengNeiSetItem/sett'



export default {
    mixins: [ LIST_MIXIN,COMMON_METHODS],
    props:[],
    components:{applyShengneiSett,audit,sett,addPayee},
    data: function(){
        return{
            pgData:{
                itemName:'',
                itemStatus:'',
                listType:this.getQuery('listType'),
            },
            tableData:[],
            formTitle:'',
            setUserId: this.$store.getters.user.userid,  //本地存的 
            rowId:'',
            setPaymentInfoDialog:false,
            applySettDialog:false,
            auditDialog:false,
            settDialog:false,
            rowguid:'',
            listName:'',
            type:'',
            listStatus:[
                {value:'待审核',key:'1',type:'info'},
                {value:'审核退回',key:'2',type:'danger'},
                {value:'待结算',key:'3',type:'warning'},
                {value:'已结算',key:'4',type:'primary'},
                {value:'已确认到账',key:'5',type:'success'},
                {value:'报送异议', key: '6', type: 'danger'},
            ],

        }
    }, 
    computed:{
 
    },
    watch:{
        applySettDialog: function(n, o){
            if(!n){
                this.doSearch();
            }
        },
        auditDialog: function(n, o){
            if(!n){
                this.reload();
            }
        },
        settDialog: function(n, o){
            if(!n){
                this.reload();
            }
        },
    },

    methods: {
        doSearch:function(){
            this.$refs.page.queryData();
        },
        reload:function(){
            this.$refs.page.reload();
        },
        //申请结算
        applyJS:function(){
            this.formTitle = '申请结算';
            this.applySettDialog = true;
        },

        // 收款信息
        setPayment: function (row) {
            this.rowId = row.id;
            this.formTitle = '设置收款信息';
            this.setPaymentInfoDialog = true;
        },

        //清单详情
        listDetail:function(row){
            if(this.getQuery('token')){
                this.goto({
                    path: './detail',
                    query: {
                        rowguid: row.id,
                        token:this.getQuery('token')
                    }
                })
            }else{
                this.goto({
                    path: './detail',
                    query: {
                        rowguid: row.id
                    }
                }) 
            }
        },

        //审核
        auditList:function(row){
            this.rowguid = row.id;
            this.listName = row.itemName;
            this.formTitle = '审核';
            this.auditDialog = true;
        },
        //结算
        settList:function(row){
            this.rowguid = row.id;
            this.listName = row.itemName;
            this.formTitle = '结算';
            this.settDialog = true;
        },

        //删除清单
        delList:function(row){
            var that = this;
            ShowConfirm('删除后需重新添加结算清单，是否确定？' , 'warning', function() {
                that.$delete(that.$store.getters.settUrl + '/settShengNei/'+row.id,{},function(data){
                    ShowMsg('删除成功','success');
                    that.reload();
                })
            }, function() {
                
            })
        },

        //导出清单
        exportList:function(){
            if(this.tableData.length !=0){
                var search = toSearch({
                    listType:this.getQuery('listType'),
                    itemName: this.pgData.itemName,
                    itemStatus: this.pgData.itemStatus,
                    token:this.getQuery('token') ? this.getQuery('token') : this.getGetters('user').token,
                    isExport: true,
                });
                downloader('/bhtong/settmg/settShengNei' + search);
            }else{
                ShowMsg('当前清单暂无数据，暂不支持导出', 'warning');
            }
        },

        //确认到账
        confirmReceipt:function(row){
            this.$confirm('请核实结算金额是否已经到账，是否确认打款无误？', '提示', {
                confirmButtonText: '确认无误',
                cancelButtonText: '报送异议',
                type: 'warning',
                distinguishCancelAndClose:true,
                closeOnClickModal: false,
            }).then(() => {
                this.$post(this.$store.getters.settUrl + '/settShengNei/itemConfirm',{
                    id:row.id,
                    confirmStatus:true,
                },function(data){
                    ShowMsg('成功','success');
                    this.reload();
                })
            }).catch((e) => {
                if(e == 'cancel'){
                    this.$post(this.$store.getters.settUrl + '/settShengNei/itemConfirm',{
                        id:row.id,
                        confirmStatus:false,
                    },function(data){
                        ShowMsg('成功','success');
                        this.reload();
                    })
                }
            });
        }
    },
    mounted() {
        if(this.getQuery('listType') == 'audit'){
            this.pgData.itemStatus = '1';
        }else if (this.getQuery('listType') == 'sett'){
            this.pgData.itemStatus = '3';
        }

        // console.log( this.$store.getters.user.userid);
    }
}
