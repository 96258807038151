
export default {
    data: () => ({

    }),
    computed: {

    },
    methods: {
        getBlob(url,cb) {
            return new Promise(resolve => {
                const xhr = new XMLHttpRequest();
                // 避免 200 from disk cache
                xhr.open('GET', url, true);
                xhr.responseType = 'blob';
                xhr.onload = () => {
                    if (xhr.status === 200) {
                        resolve(xhr.response);
                    }
                }
                xhr.send();
            })
        },
        // 保存
        // @param  {Blob} blob
        // @param  {String} filename 想要保存的文件名称
        saveAs(blob, filename) {
            if (window.navigator.msSaveOrOpenBlob) {
                navigator.msSaveBlob(blob, filename);
            } else {
                const anchor = document.createElement('a');
                const body = document.querySelector('body');
                anchor.href = window.URL.createObjectURL(blob);
                anchor.download = filename;
                anchor.style.display = 'none';
                body.appendChild(anchor);
                anchor.click();
                body.removeChild(anchor);
                window.URL.revokeObjectURL(anchor.href);
            }
        },

        async courseDownload(url, newFileName) {
            const blob = await this.getBlob(url);
            this.saveAs(blob, newFileName);
        }  
    },
    mounted() {

    }


}
