//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {

    props: {
        firstLabel:{
            type:String,
            default:'结算名称：',
        },
        rowguid:{
            type:String,
            default:'',
        },
        listName:{
            type:String,
            default:'',
        },
    },
    components: {},
    data: function () {
        return {
            form: {
                rowguid:this.rowguid,
                settFileName:'',
                settFileUrl:'',
            },
            detail:{}

        }
    },
    computed: {

    },
    watch: {


    },

    methods: {
        cancle: function () {
            this.$emit('close')
        },
        submit: function () {
            let that = this;
            // if(!that.form.settFileUrl){
            //     return ShowMsg('请上传文件','warning');
            // }
            that.$post(`${that.getGetters('settUrl')}/settShengNei/itemSett`,this.form ,function(data){
                ShowMsg('提交成功','success');
                that.cancle();
            })
        },
        getfile:function(val){
            this.form.settFileName = val.fileName;
            this.form.settFileUrl = val.fileUrl;
        },
        getDetail:function(){
            this.$get(this.$store.getters.settUrl + '/settShengNei/'+this.rowguid, {}, function(data) {
                this.detail = data;
            })
        },

    },
    mounted () {
        this.getDetail();
    }
}
