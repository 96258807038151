//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import DOWNLOAD_FILE from '@mixins/downloadFile.js'
import commonTable from '@/views/settmg/components/common_table'

export default {
    mixins: [DOWNLOAD_FILE],
    components: {commonTable},
    props: {},
    data(){
        return{
            detailData:{},
            tableData:[],
            payeeInfo:{},
            statusList:[
                {value:'待审核',key:'1',type:'info'},
                {value:'审核退回',key:'2',type:'warning'},
                {value:'待结算',key:'3',type:'primary'},
                {value:'已结算',key:'4',type:'success'},
            ],
            btnOrListShow:true
        }
    },
    computed: {
        rowguid:function(){
            return this.getQuery('rowguid')
        }  
    },
    watch:{
    },
    beforeRouteEnter (to, from, next) {
        if(!!from.query.listType && from.query.listType == 'sett'){
            next(vm =>{
                vm.btnOrListShow = false;
            })
        }else{
            next();
        }
    },
    methods: {
        getDetail:function(){
            this.$get(this.$store.getters.settUrl + '/settShengNei/'+this.rowguid, {}, function(data) {
                this.detailData = data.settItemShengNei;
                this.tableData = data.guarOrderInfo;
                // this.payeeInfo = data.settPayee;
            })
        },
        //导出
        exportList:function(){
            if(this.tableData.length !=0){
                var search = toSearch({
                    token:this.getQuery('token') ? this.getQuery('token') : this.getGetters('user').token,
                    isExport: true,
                });
                downloader('/bhtong/settmg/settShengNei/' + this.rowguid + search);
            }else{
                ShowMsg('当前清单暂无数据，暂不支持导出', 'warning');
            }
        },
        //返回
        goBack:function(){
            this.$router.go(-1);
        },

        downloadFile: function (val) {
            if(!!val){
                downloader(val);
            }else{
                ShowMsg('文件不存在','warning');
            }
        },
    },
    mounted:function(){
        this.getDetail();  
    }
}
