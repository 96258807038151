//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import sett from '@/views/settmg/views/ShengNeiSetItem/sett'

export default {
    mixins: [],
    props: [],
    components: {sett},
    data: function () {
        return {
            pgData: {
                itemStatus: '',
                accountName: '',
            },
            tableData: [],
            tableHeight:window.innerHeight - 203,

            formTitle: '',
            settDialog: false,

            rowguid: '',
            listName: '',

            imgDialog:false,
            imgSrc:'',

            listStatus: [
                { value: '待结算', key: '3', type: 'warning' },
                { value: '已结算', key: '4', type: 'primary' },
                { value: '已确认到账', key: '5', type: 'success' },
                { value: '报送异议', key: '6', type: 'danger' },
            ],
        }
    },
    computed: {

    },
    watch: {
        settDialog: function (n, o) {
            if (!n) {
                this.reload();
            }
        },

        imgDialog: function (n, o) {
            if (!n) {
                this.imgSrc = '';
            }
        },
    },

    methods: {
        doSearch: function () {
            this.$refs.page.queryData();
        },
        reload: function () {
            this.$refs.page.reload();
        },
        //结算
        settList: function (row) {
            this.rowguid = row.id;
            this.listName = row.accountName;
            this.formTitle = '结算';
            this.settDialog = true;
        },
        //查看身份证照片
        showSFZ:function(row,val){
            this.formTitle = val == 'front' ? '身份证正面照' : '身份证反面照';
            this.imgSrc = val == 'front' ? row.idcardFrontFile : row.idcardBackFile;
            this.imgDialog = true;
        },
        //导出
        exportList(){
            var search = toSearch({
                itemStatus:this.pgData.itemStatus,
                accountName:this.pgData.accountName,
                sortorder:this.pgData.sortorder,
                sortname:this.pgData.sortname,
                pageindex:this.pgData.pageindex,
                pagesize:this.pgData.pagesize,

                isExport: true,
                token:this.getQuery('token') ? this.getQuery('token') : this.getGetters('user').token
            });
            // downloader('/bhtong/settmg/settShengNei/settList' + search);     //提示 This file should be served over HTTP
            window.open('/bhtong/settmg/settShengNei/settList' + search);
        },
    },
    mounted () {

    }
}
