//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import addPayee from '@/views/settmg/views/ShengNeiSetItem/components/addPayee'
import setPaymentInfo from '@/views/user/views/userInfo/setPaymentInfo'     // 新 添加收款人，回显


export default {

    props:[],
    components:{addPayee,setPaymentInfo},
    data: function(){
        return{
            form:{
                itemAmount:'',
                settAmount:'',
                appPhone:'',
                // jbzhzh:'',
                biaoduanList:[],  
            },
            pgData:{
               settStart:'',
               settEnd:''
            },
            selectvalue:'1',
            loading:false,
            projectList:[],
            selectvalueList:[
                {value:'按日期结算',key:'1'},
                {value:'按项目结算',key:'2'},
            ],
            biaodunSelectList:[],
            biaoduanStr:'',

            payeeList:[

            ],
            dialogTitle:'',
            addPayeeDialog:false,

        }
    }, 
    computed:{
        valuetime:{
            get:function(){
                var startTime = this.pgData.settStart || '';
                var endtime = this.pgData.settEnd || '';
                    if(startTime && endtime){
                        return[
                            startTime,
                            endtime
                        ];
                    }else{
                        return[]
                    }
            },
            set:function(val){
                if(!val){
                    this.pgData.settStart = '' ;
                    this.pgData.settEnd = '';
                }else{
                    var startTime = val[0] || '';
                    var endtime = val[1] || '';
                    this.pgData.settStart = startTime;
                    this.pgData.settEnd = endtime;
                }
            },
        }  
    },
    watch:{
        selectvalue:function(n,o){
            if(n != o ){
                this.pgData.settStart = '';
                this.pgData.settEnd = '';
                this.form.settBidsection = '';
                this.biaoduanStr ='';
                this.projectList = [],
                this.biaodunSelectList = [],
                this.form.tenderProjectName='',
                this.form.biaoduanList = [],
                this.getInfo('','','')
            }
        },
        addPayeeDialog: function(n, o){
            if(!n){
                this.getPayeeList();
            }
        },

    },

    methods: {
        paySelect(val){
            console.log(val);
            let that = this;
            let data = {
                userId: val,
            };
            
            if(!val){ return}
            this.$get(`${this.getGetters('settUrl')}/settPayee/${val}`,function(data){
                    that.form.bankName = data.bankName;
                    that.form.bankMobile= data.bankMobile;
                    that.form.accountName= data.accountName;
                    that.form.accountNumber= data.accountNumber;
                    that.form.idcardNumber= data.idcardNumber;
            });
        
        },
        cancle:function(){
            this.$emit('close');
        },
        getInfo:function(val1,val2,val3){
            var that = this;
            that.$get(`${that.getGetters('settUrl')}/settShengNei/applyInfo`,{
                settStart:val1,
                settEnd:val2,
                bidsection:val3,
            },function(data){
                that.form.itemAmount= data.itemAmount;
                that.form.settAmount = data.settAmount;
                that.form.appPhone = data.appPhone;
                // that.form.jbzhzh = data.jbzhzh;
            })
        },

        submit:function(){
            var that =this;
                let obj = {
                    itemName:that.form.itemName,
                    appPhone:that.form.appPhone,
                    settType:that.selectvalue,
                    settStart:that.pgData.settStart,
                    settEnd:that.pgData.settEnd,
                    settBidsection: that.biaoduanStr,
                    payeeId:that.form.payeeId,

                    bankName:that.form.bankName,
                    accountName:that.form.accountName,
                    accountNumber:that.form.accountNumber,
                    idcardNumber:that.form.idcardNumber,
                    bankMobile:that.form.bankMobile,
                }

            that.$post('/bhtong/settmg/settShengNei/applySett', obj ,function(data){
                ShowMsg('申请成功','success');
                that.cancle();
            },true)
        },
        changeDay:function(val){
            if(val){
                this.getInfo(val[0],val[1],'');
            }else{
                 this.getInfo('','','');
            }
        },
        //项目名称远程搜索
        projectListFilter:function(query){
            if (query !== '') {
                this.loading = true;
                this.$get('/bhtong/settmg/settShengNei/listSelect',{tenderProjectName:query},function(data){
                    this.projectList = data;
                    this.loading = false;
                });
            } else {
                this.projectList = [];
            }
        }, 
        tenderProjectNameSelect:function(val){
            this.form.biaoduanList = [];
            let a = this.projectList.filter(function(item){
                return item.tenderProjectName == val
            })
            this.biaodunSelectList = a[0].bidSectionList
        },
        bianduanSelectChange:function(val){
            this.biaoduanStr = val.toString();
            this.getInfo('','',this.biaoduanStr);
        },

        //添加收款人
        addPayperson:function(){
            this.dialogTitle = '添加收款人';
            this.addPayeeDialog = true;
        },

        //获取收款人列表
        getPayeeList:function(){
            this.$get(`${this.getGetters('settUrl')}/settPayee`,{},function(data){
                this.payeeList = data;
            })  
        }

    },
    mounted() {
        this.getInfo('','','');
        this.getPayeeList();

    }
}
