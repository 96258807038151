//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {

    props: {
        rowguid:{
            type:String,
            default:'',
        },
        listName:{
            type:String,
            default:'',
        },
    },
    components: {},
    data: function () {
        return {
            form: {
                rowguid:this.rowguid,
                itemStatus:'',
                auditRemark:'',
            },
        }
    },
    computed: {

    },
    watch: {


    },

    methods: {
        cancle: function () {
            this.$emit('close')
        },
        submit: function () {
            let that = this;
            that.$post(`${that.getGetters('settUrl')}/settShengNei/itemAudit`,this.form ,function(data){
                ShowMsg('提交成功','success');
                that.cancle();
            })
        },

    },
    mounted () {

    }
}
